<!-- 
数据结构
 value:[{
 		checked: true
 	},
 	{
 		checked: true
 	},
 	{
 		checked: false
 	},
 	{
 		checked: false
 	}
 ]
TODO  竖向节点右侧数据显示待完成
 -->

<template>
	<div class="time-line-box">
		<!-- 横   排列 -->
		<div v-if="direction == 'row'" class="flex-row-center-center">
			<div v-for="(item,index) in value" :key="index" class="flex-row-center-center">
				<!-- 首节点 -->
				<div v-if="index == 0" :class="{'node-active':item.checked == true,'node-normal':item.checked == false}" class="node-circle line-row-margin-right"></div>
				<!-- 节点 -->
				<div v-if="index != 0 && index != value.length-1" :class="{'node-border-active':item.checked == true,'node-border-normal':item.checked == false}"
				 class="node-border node-line-row"></div>
				<div v-if="index != 0 && index != value.length-1" :class="{'node-active':item.checked == true,'node-normal':item.checked == false}"
				 class="node-circle line-row-margin"></div>
				<!-- 尾节点 -->
				<div v-if="index == value.length-1" :class="{'node-border-active':item.checked == true,'node-border-normal':item.checked == false}"
				 class="node-border node-line-row node-border-normal"></div>
				<div v-if="index == value.length-1" :class="{'node-active':item.checked == true,'node-normal':item.checked == false}"
				 class="node-circle node-normal line-row-margin-left"></div>
			</div>
		</div>
		<!-- 竖   排列 -->
		<div v-if="direction == 'column'" class="flex-column-center-center flex-direction-column-reverse">
			<div v-for="(item,index) in value" :key="index" class="flex-column-center-center">
				<!-- 首节点 -->
				<div v-if="index == 0" :class="{'node-border-active':item.checked == true,'node-border-normal':item.checked == false}"
				 class="node-border node-line-column"></div>
				<div v-if="index == 0" :class="{'node-active':item.checked == true,'node-normal':item.checked == false}" class="node-circle line-column-margin-right">
					<div class="node-content flex-column-center-start">
						<div :class="{'node-text-active':item.checked == true,'node-text-normal':item.checked == false}">{{ item.name }}{{ item.title }}</div>
						<div :class="{'node-text-normal':item.checked == true}">{{ item.time }}</div>
					</div>
				</div>
				<!-- 节点 -->
				<div v-if="index != 0 && index != value.length-1" :class="{'node-border-active':item.checked == true,'node-border-normal':item.checked == false}"
				 class="node-border node-line-column"></div>
				<div v-if="index != 0 && index != value.length-1" :class="{'node-active':item.checked == true,'node-normal':item.checked == false}"
				 class="node-circle line-column-margin">
					<div class="node-content flex-column-center-start">
						<div :class="{'node-text-active':item.checked == true,'node-text-normal':item.checked == false}">{{ item.name }}{{ item.title }}</div>
						<div :class="{'node-text-normal':item.checked == true}">{{ item.time }}</div>
					</div>
				</div>
				<div v-if="index != 0 && index != value.length-1" :class="{'node-border-active':item.checked == true,'node-border-normal':item.checked == false}"
				 class="node-border node-line-column"></div>
				<!-- 尾节点 -->
				<div v-if="index == value.length-1" :class="{'node-active':item.checked == true,'node-normal':item.checked == false}"
				 class="node-circle node-normal line-column-margin-left">
					<div class="node-content flex-column-center-start">
						<div :class="{'node-text-active':item.checked == true,'node-text-normal':item.checked == false}">{{ item.name }}{{ item.title }}</div>
						<div :class="{'node-text-normal':item.checked == true}">{{ item.time != "" ? item.time :"" }}</div>
					</div>
				</div>
				<div v-if="index == value.length-1" :class="{'node-border-active':item.checked == true,'node-border-normal':item.checked == false}"
				 class="node-border node-line-column node-border-normal"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "timeLine",
		props: {
			// 节点数据
			value: {
				type: Array,
				default: []
			},
			// 节点方向
			direction: {
				type: String,
				default: "row"
			}
		},
		data() {
			return {

			};
		},
		computed: {},
		mounted() {},
		methods: {}
	};
</script>

<style lang="scss" scoped>
	.time-line-box {
		padding: 10px;
	}

	.node-circle {
		width: 10px;
		height: 10px;
		border-radius: 20px;
		position: relative;
	}

	.node-content {
		position: absolute;
		width: 400px;
		height: 35px;
		left: 20px;
		top: -13px;
	}

	// 节点默认颜色
	.node-normal {
		background-color: #ccc;
	}

	// 节点激活颜色
	.node-active {
		background-color: #ff6600;
	}


	// 竖节点
	.flex-direction-column-reverse {
		flex-direction: column-reverse;
	}

	.node-line-column {
		width: 0px;
		height: 16px;
	}

	.line-column-margin {
		margin: 4px 0;
	}

	.line-column-margin-top {
		margin-top: 4px;
	}

	.line-column-margin-bottom {
		margin-bottom: 4px;
	}

	// 横节点
	.node-line-row {
		width: 16px;
		height: 0px;
	}

	.line-row-margin {
		margin: 0 4px;
	}

	.line-row-margin-left {
		margin-left: 4px;
	}

	.line-row-margin-right {
		margin-right: 4px;
	}

	// 节点虚线
	.node-border {
		border: 1px dashed;
	}

	// 节点虚线默认颜色
	.node-border-normal {
		border-color: #ccc;
	}

	// 节点虚线激活颜色
	.node-border-active {
		border-color: #ff6600;
	}

	// 节点字体默认颜色
	.node-text-normal {
		color: #ccc;
	}

	// 节点字体激活颜色
	.node-text-active {
		color: #ff6600;
	}
</style>
